<template>
	<div role="progressbar" class="mdc-linear-progress mdc-linear-progress--indeterminate" :aria-label="label" aria-valuemin="0" aria-valuemax="1">
		<div class="mdc-linear-progress__buffer">
			<div class="mdc-linear-progress__buffer-bar"></div>
			<div class="mdc-linear-progress__buffer-dots"></div>
		</div>
		<div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
			<span class="mdc-linear-progress__bar-inner"></span>
		</div>
		<div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
			<span class="mdc-linear-progress__bar-inner"></span>
		</div>
	</div>
</template>

<script>
import { MDCLinearProgress } from '@material/linear-progress'

export default {
	name: 'LoadingLine',
	props: {
		label: String,
	},
	data: () => ({
		bar: null,
	}),
	mounted() {
		this.bar = new MDCLinearProgress(this.$el)
	},

}
</script>

<style scoped lang="scss">
@use "@material/linear-progress";

@include linear-progress.core-styles;
</style>
